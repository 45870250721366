<template>
   <div>
      <loadingScreen :isLoading="isLoading"/>
      <div class="row justify-content-md-center">
         <div class="login-screen">
            <div class="login-box text-center">
               <a href="#" class="login-logo">
                  <img style="max-width: 200px; margin:auto" src="@/assets/img/logo.png" alt="R2 Soft">
               </a>
               <h4>{{ t('GENERAL.MSG.CANOT_ENTER') }}</h4>
               <h5 class="mt-2">{{ t('GENERAL.MSG.INFOR_YOUR_EMAIL_TO_GET_INFO_ABOUT_RESET_YOUR_PASSWORD') }}</h5>
               <div class="form-group">
                  <input type="text" id="email" name="email" v-model="email" class="form-control"
                         @keyup="clearMail()" placeholder="Endereço de e-mail">
                  <div class="validation" id="invalidEmail" style="display: none">{{ msgEmail }}</div>
               </div>
               <div class="actions align-right">
                  <span data-cy="recover_password_button" class="btn btn-primary"
                        @click="reset()">{{ t('GENERAL.RECOVER_PASSWORD') }} </span>
               </div>
               <hr>
               <div class="forgot-pwd content-center">
                  <router-link class="home-bottom" to="/">
                     {{ t('GENERAL.MSG.BACK_TO_LOGIN') }}
                  </router-link>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import User from '@/services/user';
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import LoadingScreen from '@/components/layouts/loadScreenComponent.vue';

export default {
   name: 'RecoverPassword',
   mixins: [Validate],
   setup() {
      const toast = useToast();
      const {t} = useI18n();
      return {
         t,
         toast
      }
   },

   components: {
      LoadingScreen,
   },

   data() {
      return {
         email: null,
         isLoading: null,
      }
   },

   methods: {
      clearMail(){
         if (this.email){
            this.email = this.email.trim();
            this.email = this.email.toLowerCase();
         }
         this.inputEmail(this.email)
      },
      reset() {
         this.invalid = [];

         this.emailValidate(this.email);

         if (this.invalid.length == 0) {
            this.isLoading = true;

            User.recoverPassword({email: this.email}).then(resp => {
               if (resp.data.success == false) {
                  this.toast.error(this.t('GENERAL.MSG.DONT_HAVE_ACCOUNT_WITH_THIS_EMAIL'), {timeout: false});
               } else if (resp.data.success == true) {
                  this.toast.success(this.t('GENERAL.MSG.EMAIL_SENT_WITH_SUCCESS'));
                  this.$router.push({name: 'Login'});
               }

               this.isLoading = false;

            }).catch(error => {
               this.isLoading = false;
               this.error(error);
            });
         }
      }
   }
}
</script>

<style>
.content-center {
   justify-content: center !important;
}

.home-bottom {
   margin-top: 6px;
   color: #777b82 !important;
   font-size: .9rem;
   line-height: 150%;
   font-weight: 400;
}
</style>
